import React from 'react';
import Loader from '../../assets/loader-svg.svg';
import { Box, Grid } from '@mui/material';

export default function Spinner() {
    return (
        <Grid
            direction="column"
            display="flex"
            justifyContent="center"
            sx={{
                height: '100%',
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                background: 'white',
                zIndex: 4,
            }}
            container
        >
            <Box display="flex" justifyContent="center">
                <Loader />
            </Box>
        </Grid>
    );
}
