/* eslint-disable max-len */
import React from 'react';

const icon = ({ onClick, className, style = {} }) => (
    <svg
        onClick={onClick}
        className={className}
        style={style}
        width="180"
        height="24"
        viewBox="0 0 180 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m 89.611188,8.12727 h 2.1969 v 2.61813 c 0.4064,-0.82159 0.9978,-1.53411 1.726,-2.07983 0.6924,-0.5063 1.5272,-0.77199 2.3801,-0.75756 0.8579,-0.0449 1.7021,0.22907 2.3761,0.77078 0.674,0.54171 1.131,1.31407 1.285,2.17301 0.430002,-0.8524 1.058002,-1.58476 1.831002,-2.1331 0.709,-0.52354 1.562,-0.80714 2.439,-0.81069 2.558,0 3.838,1.45747 3.838,4.37249 v 9.456 h -2.171 v -9.1038 c 0.057,-0.7219 -0.12,-1.443 -0.503,-2.0534 -0.204,-0.2335 -0.458,-0.4152 -0.743,-0.5306 -0.285,-0.11541 -0.593,-0.16132 -0.899,-0.13392 -0.679,4.2e-4 -1.333,0.26152 -1.83,0.73102 -0.602,0.5808 -1.051,1.3047 -1.308,2.1065 -0.336002,1.0441 -0.497002,2.1379 -0.477002,3.2362 v 5.7547 h -2.171 v -9.1038 c 0.057,-0.722 -0.12,-1.4431 -0.503,-2.0535 -0.2018,-0.2379 -0.4567,-0.4226 -0.7437,-0.5383 -0.287,-0.11577 -0.5972,-0.15906 -0.9043,-0.12617 -0.6772,1.2e-4 -1.3291,0.26137 -1.8242,0.73107 -0.612,0.5825 -1.0628,1.318 -1.3076,2.133 -0.3402,1.0728 -0.5015,2.1958 -0.4773,3.3226 v 5.6484 h -2.197 z"
            fill="#8223d2"
        />
        <path
            d="m 116.76429,21.9293 c -1.181,0.0391 -2.348,-0.2751 -3.356,-0.9035 -1.007,-0.6284 -1.811,-1.5434 -2.312,-2.6318 -0.508,-1.0894 -0.772,-2.2798 -0.772,-3.4853 0,-1.2055 0.264,-2.396 0.772,-3.4854 0.513,-1.0789 1.319,-1.98542 2.324,-2.61207 1.005,-0.62665 2.166,-0.94705 3.344,-0.92312 1.173,-0.02234 2.327,0.29922 3.324,0.9262 0.997,0.62699 1.796,1.53279 2.299,2.60899 0.52,1.0861 0.789,2.2778 0.789,3.4854 0,1.2075 -0.269,2.3993 -0.789,3.4853 -0.491,1.0857 -1.287,2.0001 -2.287,2.6289 -1,0.6288 -2.16,0.944 -3.336,0.9064 z m 0,-1.9935 c 0.799,0.0197 1.583,-0.2278 2.23,-0.7045 0.633,-0.4669 1.128,-1.1012 1.432,-1.834 0.318,-0.7884 0.481,-1.633 0.477,-2.4853 0.006,-0.8439 -0.157,-1.6801 -0.477,-2.4587 -0.298,-0.7454 -0.794,-1.392 -1.432,-1.8673 -0.652,-0.4625 -1.435,-0.69587 -2.23,-0.66451 -0.802,-0.0276 -1.592,0.20511 -2.255,0.66451 -0.628,0.4852 -1.119,1.129 -1.426,1.8673 -0.323,0.7776 -0.486,1.6145 -0.477,2.4587 -0.007,0.8526 0.156,1.6978 0.477,2.4853 0.313,0.7256 0.804,1.3572 1.426,1.834 0.659,0.4731 1.449,0.7199 2.255,0.7045 z"
            fill="#8223d2"
        />
        <path
            d="m 131.90729,21.9293 c -0.811,0.0025 -1.611,-0.1911 -2.334,-0.5649 -0.625,-0.2985 -1.163,-0.7564 -1.563,-1.329 v 1.648 h -2.203 V 2.5653 h 2.203 v 7.20997 c 0.473,-0.59597 1.07,-1.07749 1.749,-1.40966 0.678,-0.33218 1.421,-0.50669 2.174,-0.5108 1.156,-0.03633 2.296,0.28334 3.269,0.91703 0.909,0.60338 1.619,1.46876 2.04,2.48526 0.925,2.3207 0.925,4.9159 0,7.2366 -0.424,1.0219 -1.133,1.8952 -2.04,2.5119 -0.982,0.6358 -2.13,0.9576 -3.295,0.9237 z m -0.216,-1.9936 c 0.553,0.0251 1.102,-0.0878 1.602,-0.3287 0.499,-0.241 0.932,-0.6027 1.262,-1.0535 0.745,-1.0587 1.118,-2.3413 1.059,-3.6415 0.061,-1.2944 -0.312,-2.5714 -1.059,-3.6216 -0.327,-0.4553 -0.759,-0.8215 -1.259,-1.0661 -0.499,-0.24465 -1.051,-0.36006 -1.605,-0.33604 -0.553,-0.01996 -1.103,0.09723 -1.602,0.34154 -0.499,0.2442 -0.932,0.6083 -1.261,1.0606 -0.73,1.0575 -1.093,2.3314 -1.033,3.6216 -0.058,1.296 0.305,2.5755 1.033,3.6415 0.33,0.4495 0.764,0.8103 1.263,1.0511 0.499,0.2408 1.048,0.3545 1.6,0.3311 z"
            fill="#8223d2"
        />
        <path
            d="m 140.50529,8.12726 h 2.197 V 21.7365 h -2.197 z m 1.085,-2.2992 c -0.21,0.00418 -0.419,-0.03427 -0.614,-0.11299 -0.195,-0.07873 -0.373,-0.19619 -0.523,-0.34556 -0.296,-0.31158 -0.459,-0.72983 -0.451,-1.1629 -0.007,-0.21381 0.03,-0.42666 0.107,-0.6255 0.078,-0.19885 0.195,-0.37948 0.344,-0.53075 0.149,-0.1515 0.326,-0.27082 0.522,-0.35075 0.195,-0.07994 0.405,-0.11882 0.615,-0.11438 0.211,-0.00681 0.421,0.03097 0.617,0.11105 0.196,0.08008 0.373,0.20066 0.521,0.35408 0.151,0.15147 0.271,0.33201 0.353,0.53107 0.082,0.19907 0.124,0.4127 0.124,0.6285 0,0.2158 -0.042,0.42943 -0.124,0.6285 -0.082,0.19907 -0.202,0.3796 -0.353,0.53108 -0.148,0.14519 -0.323,0.2588 -0.515,0.33412 -0.192,0.07532 -0.397,0.11085 -0.603,0.10448 z"
            fill="#8223d2"
        />
        <path d="m 146.11529,2.59856 h 2.197 V 21.7166 h -2.197 z" fill="#8223d2" />
        <path
            d="m 163.01629,15.2642 h -9.807 c -0.003,1.2185 0.387,2.4043 1.111,3.3757 0.334,0.4301 0.762,0.7746 1.25,1.0055 0.488,0.2309 1.023,0.3417 1.562,0.3235 0.797,0.0348 1.586,-0.1835 2.256,-0.6246 0.672,-0.5259 1.208,-1.2101 1.562,-1.9936 l 1.85,0.8107 c -0.5,1.1691 -1.305,2.1776 -2.327,2.9173 -1.016,0.6433 -2.198,0.9627 -3.394,0.917 -1.158,0.0309 -2.299,-0.2983 -3.269,-0.9436 -0.918,-0.6485 -1.649,-1.5339 -2.118,-2.5651 -0.483,-1.1039 -0.729,-2.3002 -0.719,-3.5085 -0.014,-1.2109 0.232,-2.4103 0.719,-3.5154 0.462,-1.0357 1.195,-1.92288 2.118,-2.56497 0.97,-0.64537 2.111,-0.9746 3.269,-0.94364 1.119,-0.02787 2.222,0.27205 3.178,0.8639 0.892,0.5627 1.61,1.36861 2.073,2.32581 0.481,1.0081 0.725,2.1159 0.712,3.2362 z m -2.229,-1.9205 c -0.104,-0.9506 -0.524,-1.8367 -1.19,-2.5119 -0.341,-0.3221 -0.741,-0.5715 -1.178,-0.7336 -0.437,-0.16209 -0.901,-0.23345 -1.366,-0.20995 -0.87,-0.00786 -1.713,0.31165 -2.366,0.89705 -0.72,0.6777 -1.197,1.5806 -1.354,2.565 z"
            fill="#8223d2"
        />
        <path
            d="m 169.88129,10.4066 h -3.504 V 8.95128 h 3.504 V 5.4161 h 1.419 v 3.53518 h 3.485 v 1.45532 h -3.485 v 3.5551 h -1.419 z"
            fill="#8223d2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.8047 17.1315C20.7995 17.3777 20.8429 17.6224 20.9324 17.8513C21.0219 18.0801 21.1556 18.2884 21.3257 18.4637C21.4958 18.6391 21.6989 18.7779 21.9227 18.8721C22.1466 18.9663 22.3866 19.0139 22.6289 19.0121C22.862 19.0359 23.0976 19.0076 23.3188 18.9293C23.5401 18.851 23.7418 18.7245 23.9099 18.5587C24.0781 18.3929 24.2086 18.1917 24.2922 17.9693C24.3759 17.7469 24.4106 17.5086 24.3942 17.271C24.3942 15.6961 23.0409 15.5898 21.6874 15.1978C20.8533 15.0055 20.1109 14.5245 19.5872 13.8372C19.0636 13.1498 18.7915 12.2988 18.8172 11.4299C18.8297 10.5893 19.1077 9.77511 19.6102 9.10738C20.1128 8.43964 20.8133 7.95381 21.6083 7.72149C22.4032 7.48916 23.2506 7.5227 24.0253 7.81712C24.8001 8.11155 25.4612 8.65124 25.9112 9.35665L24.0282 11.2373C23.9468 10.9737 23.7864 10.7423 23.5696 10.5756C23.3528 10.4089 23.0903 10.315 22.8186 10.3069C22.6689 10.2993 22.5193 10.3237 22.3794 10.3784C22.2396 10.4332 22.1127 10.5171 22.0067 10.6248C21.9007 10.7325 21.8182 10.8615 21.7644 11.0037C21.7105 11.1458 21.6865 11.2978 21.694 11.4499C21.694 12.6859 23.237 12.6593 24.7277 13.2242C25.547 13.4897 26.2552 14.0269 26.7388 14.7497C27.2224 15.4725 27.4536 16.3392 27.3954 17.2113C27.3954 20.0155 25.388 21.9226 22.6027 21.9226C21.9774 21.943 21.3547 21.8327 20.7729 21.5988C20.1912 21.3649 19.6628 21.0123 19.2204 20.5626C18.778 20.113 18.4309 19.5759 18.2007 18.9847C17.9706 18.3934 17.8621 17.7604 17.8821 17.1249L20.8047 17.1315Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.3089 21.7632H38.236V20.1616C37.774 20.7375 37.187 21.1967 36.5211 21.5034C35.8552 21.8101 35.1285 21.9559 34.3979 21.9293C30.9261 21.9293 29.0234 19.8825 29.0234 16.148V7.72861H32.103V15.4769C32.103 17.9489 33.1361 19.0121 35.1499 19.0121C37.1637 19.0121 38.236 17.5568 38.236 15.4769V7.72861H41.3089V21.7632Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.8328 7.7286H46.8926V9.1905C47.5465 8.28676 48.5337 7.56247 50.4887 7.56247V10.5395H50.2076C49.7591 10.5236 49.3122 10.6035 48.8959 10.774C48.4797 10.9445 48.1034 11.2018 47.791 11.5293C47.4786 11.8568 47.2373 12.2472 47.0826 12.6753C46.9279 13.1034 46.8631 13.5598 46.8926 14.0149V21.7631H43.8328V7.7286Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.941 2.00052C54.3515 1.99127 54.7553 2.10651 55.101 2.33156C55.4467 2.5566 55.7186 2.88121 55.882 3.264C56.0455 3.6468 56.093 4.07041 56.0185 4.48076C55.9441 4.89111 55.751 5.26964 55.464 5.56798C55.1769 5.86633 54.8089 6.07098 54.4069 6.15584C54.005 6.24071 53.5872 6.20194 53.207 6.04447C52.8268 5.88699 52.5014 5.61792 52.2723 5.27166C52.0431 4.9254 51.9207 4.5176 51.9206 4.10032C51.9154 3.82702 51.9637 3.55543 52.0628 3.30132C52.162 3.0472 52.3099 2.81569 52.498 2.62025C52.686 2.42481 52.9104 2.2694 53.1582 2.16299C53.4059 2.05659 53.672 2.00135 53.941 2.00052ZM52.4372 21.7632H55.4383V7.76846H52.4241L52.4372 21.7632Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M64.4609 7.56244C65.8604 7.56244 67.2285 7.98431 68.392 8.77466C69.5555 9.56501 70.4622 10.6883 70.9974 12.0025C71.5327 13.3167 71.6724 14.7627 71.3989 16.1577C71.1254 17.5526 70.4511 18.8336 69.461 19.8389C68.471 20.8442 67.2098 21.5285 65.837 21.8052C64.4643 22.0819 63.0417 21.9385 61.7492 21.3933C60.4566 20.8481 59.3522 19.9255 58.5756 18.7422C57.7991 17.5589 57.3853 16.1681 57.3866 14.7458C57.3613 13.7949 57.5271 12.8488 57.8738 11.9653C58.2204 11.0817 58.7407 10.2792 59.4028 9.60693C60.0649 8.93461 60.8549 8.4065 61.7246 8.05499C62.5943 7.70347 63.5253 7.5359 64.4609 7.56244ZM64.4609 18.9589C66.8343 18.9589 68.4362 17.1912 68.4362 14.7525C68.4362 12.3137 66.8343 10.5328 64.4609 10.5328C62.0875 10.5328 60.4857 12.307 60.4857 14.7525C60.4857 17.1979 62.0875 18.9589 64.4609 18.9589Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M86.1747 21.7631H83.2194V13.9617C83.2194 13.0576 82.8659 12.1906 82.2369 11.5513C81.6079 10.912 80.7548 10.5528 79.8652 10.5528C78.9756 10.5528 78.1225 10.912 77.4935 11.5513C76.8644 12.1906 76.5111 13.0576 76.5111 13.9617V21.7631H73.5493V13.9617C73.602 12.2959 74.2902 10.7162 75.4683 9.55674C76.6464 8.3973 78.2221 7.74904 79.862 7.74904C81.5019 7.74904 83.0774 8.3973 84.2555 9.55674C85.4337 10.7162 86.122 12.2959 86.1747 13.9617V21.7631Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.85946 7.56247C7.02129 7.61928 5.27707 8.40134 3.9966 9.74291C2.71614 11.0845 2 12.8801 2 14.7492C2 16.6182 2.71614 18.4138 3.9966 19.7554C5.27707 21.097 7.02129 21.8791 8.85946 21.9359C9.61478 21.9578 10.3655 21.8102 11.0583 21.5035C11.7511 21.1968 12.3687 20.7387 12.8674 20.1616V21.7631H15.8881V14.7724C15.8941 13.8295 15.7169 12.8946 15.3666 12.0214C15.0163 11.1482 14.4998 10.354 13.8468 9.6841C13.1938 9.01424 12.4172 8.48196 11.5613 8.11788C10.7054 7.7538 9.78726 7.56507 8.85946 7.56247ZM8.85946 18.8592C8.06049 18.8645 7.27796 18.6285 6.61112 18.1812C5.94428 17.7339 5.42302 17.0954 5.11367 16.3467C4.80432 15.598 4.72074 14.7728 4.87343 13.9757C5.02612 13.1786 5.40821 12.4456 5.97134 11.8695C6.53447 11.2934 7.25333 10.9003 8.03658 10.7399C8.81983 10.5796 9.63229 10.6591 10.371 10.9687C11.1096 11.2782 11.7411 11.8037 12.1856 12.4785C12.63 13.1533 12.8673 13.9471 12.8674 14.7591C12.8656 15.8402 12.4432 16.8766 11.6923 17.6423C10.9414 18.408 9.92311 18.8407 8.85946 18.8459V18.8592Z"
            fill="black"
        />
    </svg>
);

export default icon;
