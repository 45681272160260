import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Seo from '../components/SeoComponent';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import sanitizeHtml from 'sanitize-html';
import PhoneComponent from '../components/PhoneComponent';
import { Box, Button, Card, CardContent, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import SpinnerOverlay from '../components/common/SpinnerOverlay';
import Loader from '../assets/loader-svg.svg';

import { substituteCityName, repairRenderingOptions } from '../utils/Utils';

import axios from 'axios';

const RepairTemplate = (props) => {
    const [pageLoading, setPageLoading] = useState(true);
    const [postcode, setPostCode] = useState(null);
    const [postOffices, setPostOffices] = useState(null);
    const [loadingResults, setLoadingResults] = useState(null);
    const [postCodeValid, setPostCodeValid] = useState(false);
    const cityName = props && props.pageContext && props.pageContext.city ? props.pageContext.city : null;
    const cityZip = props && props.pageContext && props.pageContext.zip ? props.pageContext.zip : null;

    useEffect(() => {
        if (cityName && cityZip) {
            searchAUSPost(cityZip);
        }
        setPageLoading(false);
    }, [cityName, cityZip]);

    const handleChange = (event) => {
        if (event.target.value.length === 4 && !isNaN(Number(event.target.value))) {
            setPostCodeValid(true);
        } else {
            setPostCodeValid(false);
        }
        setPostCode(event.target.value);
    };

    const searchAUSPost = (postcode) => {
        setLoadingResults(true);
        const key = process.env.AUSPOST_API_KEY;

        const query = new URLSearchParams({
            pc: 'AP',
            types: 'PO',
        }).toString();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'AUTH-KEY': key,
            },
        };

        axios
            .get(
                `https://digitalapi.auspost.com.au/locations/v2/points/postcode/${postcode.toString()}?${query}`,
                config
            )
            .then((res) => {
                setPostOffices(res.data.points.slice(0, 5));
            })
            .catch((err) => {})
            .finally(() => {
                setLoadingResults(false);
            });
    };

    if (typeof Number.prototype.toRad === 'undefined') {
        Number.prototype.toRad = function () {
            return (this * Math.PI) / 180;
        };
    }
    const solveDistanceBetweenTwoCoordinates = (officeLat, officeLong, userLat, userLong) => {
        var R = 6371; // km
        var dLat = (parseFloat(officeLat) - parseFloat(userLat)).toRad();
        var dLon = (parseFloat(officeLong) - parseFloat(userLong)).toRad();
        var lat1 = parseFloat(officeLat).toRad();
        var lat2 = parseFloat(userLat).toRad();

        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;

        return d.toFixed(2);
    };

    const useStyles = makeStyles({
        repairParagraphs: {
            '& > b': {
                fontWeight: 'normal',
                color: '#6700b8',
            },
        },
    });

    const classNames = useStyles();

    const adData = get(props, 'data.DeviceData');

    const postBrandName = adData && adData.brandName ? sanitizeHtml(adData.brandName) : '';

    const postTitle = adData && adData.title ? sanitizeHtml(adData.title) : '';
    const postPrice = adData && adData.adBlockDynamicPrice ? sanitizeHtml(adData.adBlockDynamicPrice) : '0.0';
    const postPromoText = adData && adData.adBlockPromoText ? adData.adBlockPromoText : '';
    const postDescription = adData && adData.adBlockDescription ? adData.adBlockDescription : '';

    const repairs = get(props, 'data.repairData');
    const metaTags = get(props, 'data.metaTags');

    const heading = repairs && repairs.deviceTitle ? substituteCityName(repairs.deviceTitle, cityName) : '';
    const asurionDescription =
        repairs && repairs.asurionDescription ? substituteCityName(repairs.asurionDescription, cityName) : '';
    const repair1Heading =
        repairs && repairs.repair1Heading ? substituteCityName(repairs.repair1Heading, cityName) : '';
    const repair1Description = repairs && repairs.repair1Description ? repairs.repair1Description : '';
    const repairImage = repairs && repairs.repairImage ? repairs.repairImage.file.url : '';
    const repairImage1 = repairs && repairs.repairImage ? repairs.repairImage : '';

    const repair2Heading =
        repairs && repairs.repair2Heading ? substituteCityName(repairs.repair2Heading, cityName) : '';
    const repair2Description = repairs && repairs.repair2Description ? repairs.repair2Description : '';
    const repair3Heading =
        repairs && repairs.repair3Heading ? substituteCityName(repairs.repair3Heading, cityName) : '';
    const repair3Description = repairs && repairs.repair3Description ? repairs.repair3Description : '';
    const repairProcessTitle =
        repairs && repairs.repairProcessTitle ? substituteCityName(repairs.repairProcessTitle, cityName) : '';
    const repairProcessContent = repairs && repairs.repairProcessContent ? repairs.repairProcessContent : '';
    const repairMedia4 = repairs && repairs.repairMedia4 ? repairs.repairMedia4 : '';
    const metaTitle = cityName
        ? `${postTitle} | ${cityName} | Repair | Asurion Mobile+`
        : postTitle + ' | Repair | Asurion Mobile+';
    const metaDescription = metaTags && metaTags.description ? substituteCityName(metaTags.description, cityName) : '';
    const ogImage = metaTags && metaTags.ogImage ? metaTags.ogImage.file.url : '';

    // const domainUrl = process.env.GATSBY_DOMAIN_URL || '';
    const domainUrl = '';
    const canonicalUrl =
        props && props.pageContext && props.pageContext.canonicalPath
            ? domainUrl + props.pageContext.canonicalPath
            : domainUrl + props.path;

    const renderingOptions = repairRenderingOptions(cityName, classNames);

    return (
        <PhoneComponent
            postTitle={postTitle}
            brandName={postBrandName}
            adBlockDynamicPrice={postPrice}
            adBlockPromoText={postPromoText}
            adBlockDescription={postDescription}
            activeTab={'repair'}
            canonicalUrl={canonicalUrl}
            subcomponent={
                <>
                    {pageLoading ? <SpinnerOverlay /> : ''}
                    <Seo
                        title={metaTitle}
                        description={metaDescription}
                        ogImage={ogImage}
                        image={ogImage}
                        style={{ fontSize: '22px', lineHeight: '18px' }}
                        canonical={canonicalUrl}
                    />
                    <Box
                        variant="div"
                        sx={{ width: { xs: 1, md: '60%' }, px: { xs: '20px', md: 0 }, margin: '0 auto' }}
                    >
                        <Box mb={3}>
                            <Typography style={{ fontSize: '1.5rem', lineHeight: '2rem' }}>
                                {cityName ? (
                                    <>
                                        <Typography variant="span" sx={{ color: '#8223D2' }}>
                                            {postTitle}
                                        </Typography>
                                        {` `}
                                        {heading} in{' '}
                                        <Typography variant="span" sx={{ color: '#8223D2' }}>
                                            {cityName}
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <Typography variant="span" sx={{ color: '#8223D2' }}>
                                            {postTitle}
                                        </Typography>
                                        {` `}
                                        {heading}
                                    </>
                                )}
                            </Typography>
                            {asurionDescription && (
                                <Typography style={{ margin: '16px 0px 20px 0px' }}>
                                    {renderRichText(asurionDescription, renderingOptions)}
                                </Typography>
                            )}
                        </Box>
                        <Box mb={3}>
                            {repair1Heading && (
                                <Typography style={{ fontSize: '20px', lineHeight: '24px', marginBottom: '12px' }}>
                                    {repair1Heading}
                                </Typography>
                            )}
                            {repair1Description && (
                                <Typography>{renderRichText(repair1Description, renderingOptions)}</Typography>
                            )}
                            {repairImage && (
                                <Box
                                    component="div"
                                    sx={{ height: 'auto', width: '100%', display: 'flex', margin: '0 auto' }}
                                    style={{ marginTop: '24px', marginBottom: '30px' }}
                                >
                                    <GatsbyImage image={getImage(repairImage1)} alt={'Repair for this device'} />
                                </Box>
                            )}
                        </Box>
                        <Box mb={3}>
                            {repair2Heading && (
                                <Typography style={{ fontSize: '20px', lineHeight: '24px', marginBottom: '12px' }}>
                                    {repair2Heading}
                                </Typography>
                            )}
                            {repair2Description && (
                                <Typography style={{ margin: '16px 0px 20px 0px' }}>
                                    {renderRichText(repair2Description, renderingOptions)}
                                </Typography>
                            )}
                        </Box>
                        <Box mb={3}>
                            {repair3Heading && (
                                <Typography style={{ fontSize: '20px', lineHeight: '24px', marginBottom: '12px' }}>
                                    {repair3Heading}
                                </Typography>
                            )}
                            {repair3Description && (
                                <Typography style={{ margin: '16px 0px 20px 0px' }}>
                                    {renderRichText(repair3Description, renderingOptions)}
                                </Typography>
                            )}
                        </Box>
                        <Box>
                            {repairMedia4 && (
                                <Box
                                    component="div"
                                    sx={{ height: 'auto', width: '100%', display: 'flex', margin: '0 auto' }}
                                    style={{ marginTop: '24px', marginBottom: '30px' }}
                                >
                                    <GatsbyImage image={getImage(repairMedia4)} alt={'Repair for this device'} />
                                </Box>
                            )}
                            {repairProcessTitle && (
                                <Typography style={{ fontSize: '20px', lineHeight: '24px', marginBottom: '12px' }}>
                                    <Typography variant="span" sx={{ color: '#8223D2' }}>
                                        {postTitle}
                                    </Typography>
                                    {` `}
                                    {repairProcessTitle}
                                </Typography>
                            )}
                            {repairProcessContent && (
                                <Typography>{renderRichText(repairProcessContent, renderingOptions)}</Typography>
                            )}
                        </Box>
                        <Box mt={4}>
                            <Typography style={{ fontSize: '20px', lineHeight: '24px', marginBottom: '20px' }}>
                                Find the nearest dropoff point
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    label={'Post Code'}
                                    id="aus-post-code"
                                    type="text"
                                    autoComplete="none"
                                    value={postcode}
                                    onChange={handleChange}
                                    inputProps={{
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*',
                                        maxLength: 4,
                                    }}
                                    style={{ marginRight: '20px' }}
                                />
                                <Button
                                    variant="contained"
                                    disabled={postCodeValid ? false : true}
                                    onClick={() => {
                                        searchAUSPost(postcode);
                                    }}
                                >
                                    Check
                                </Button>
                            </Box>
                            <Typography color="primary" variant="caption" sx={{ ml: 1.5 }}>
                                Post Code must be 4 digits.
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems={loadingResults ? 'center' : 'start'}
                            sx={{ height: loadingResults ? '30em' : '' }}
                        >
                            {loadingResults ? (
                                <Box sx={{ height: '5em' }}>
                                    <Loader />
                                </Box>
                            ) : (
                                <Box>
                                    {postOffices !== null ? (
                                        <>
                                            {postOffices.length === 0 ? (
                                                <Box sx={{ py: 2 }}>
                                                    <Typography color="error">
                                                        There are no nearby post offices. Please try another Post Code.
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                <Box display="flex" flexDirection="column" sx={{ py: 2 }}>
                                                    {postOffices.map((office) => (
                                                        <Card
                                                            id={office.location_code}
                                                            sx={{ mb: 2, background: '#F0F3FF' }}
                                                        >
                                                            <CardContent>
                                                                <Typography>
                                                                    <b>Outlet:</b> {office.name}
                                                                </Typography>
                                                                <Typography>
                                                                    <b>Telephone Number:</b> {office.phone_number}
                                                                </Typography>
                                                                <Typography>
                                                                    <b>Address:</b> {office.address.address_line_1}{' '}
                                                                    {office.address.suburb} {office.address.state}
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    ))}
                                                </Box>
                                            )}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </>
            }
        />
    );
};

export default RepairTemplate;

export const repairQuery = graphql`
    query repairQuery($title: String, $metaTagTitle: String) {
        DeviceData: contentfulDeviceDetailPage(title: { eq: $title }) {
            brand
            brandName
            title
            adBlockDynamicPrice
            adBlockPromoText {
                raw
            }
            adBlockDescription {
                raw
            }
        }
        repairData: contentfulScreenRepair(metaTagTitle: { eq: $metaTagTitle }) {
            deviceTitle
            asurionDescription {
                raw
            }
            repair1Heading
            repair1Description {
                raw
            }
            repairImage {
                file {
                    url
                }
                gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE, width: 700)
            }
            repair2Heading
            repair2Description {
                raw
            }
            repair3Heading
            repair3Description {
                raw
            }
            repairProcessTitle
            repairProcessContent {
                raw
            }
            repairMedia4 {
                file {
                    url
                }
                gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE, width: 700)
            }
        }
        metaTags: contentfulMetaTags(title: { eq: $metaTagTitle }) {
            title
            robots
            canonical
            description
            ogImage {
                file {
                    url
                    fileName
                }
            }
        }
    }
`;
